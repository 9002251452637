import React, { useState } from 'react';
import axios from 'axios';
import { Radio, Tooltip, Upload, message, Space, Image } from 'antd';
import { CloseCircleTwoTone, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import Utils from '../../../misc/Utils';
import { sub } from 'date-fns';

export default function MultipleChoiceImageItem(props) {
   var subdomain = localStorage.getItem("lms-subdomain");
   // const [value, setValue] = useState(props.value);
   const [loading, setLoading] = useState(false);
   // const [imageURL, setImageURL] = useState(props.image_url !== '' ? `${process.env.REACT_APP_API_S3_URL}/${props.image_url}` : '');
   let imgPath = '';
   if (props.assessment_type === 'summative' || props.assessment_type === 'formative')
      imgPath = props.image_url !== "" && props.image_url !== undefined && props.image_url !== null ? `${process.env.REACT_APP_API_S3_URL}/${subdomain}/${props.image_url.substring(props.image_url.indexOf('assessment'), props.image_url.length)}` : "";

   if (props.assessment_type === 'mastery' || props.assessment_type === 'diagnostic')
      imgPath = props.image_url !== "" && props.image_url !== undefined && props.image_url !== null ? `${process.env.REACT_APP_API_S3_URL}/${subdomain}/${props.image_url.substring(props.image_url.indexOf('mastery-assessment'), props.image_url.length)}` : "";

   const [imageURL, setImageURL] = useState(props.image_url !== '' && props.image_url !== undefined && props.image_url !== null ? (props.image_url.includes('base64') ? props.image_url : imgPath) : '');

   const beforeUpload = (file) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
         message.error('Only JPG and PNG files are allowed.');
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
         message.error('Image must smaller than 2MB.');
      }
      // return isJpgOrPng && isLt2M;
      handleUploadImage(file);
   }

   const handleUploadImage = file => {
      setImageURL('');
      setLoading(true);

      const formData = new FormData();
      formData.append('file', file);

      // const url = `${process.env.REACT_APP_API_S3_UPLOAD}/uploadASSFile?s3path=${process.env.REACT_APP_S3_ASSESSMENT_IMAGES_PATH}`;
      let url = '';

      if (props.assessment_type === 'mastery')
         url = `${process.env.REACT_APP_API_S3_UPLOAD}/uploadASSFile?s3path=${process.env.REACT_APP_S3_MASTERY_ASSESSMENT_IMAGES_PATH}`;

      if (props.assessment_type === 'diagnostic')
         url = `${process.env.REACT_APP_API_S3_UPLOAD}/uploadASSFile?s3path=${process.env.REACT_APP_S3_MASTERY_ASSESSMENT_IMAGES_PATH}`;

      if (props.assessment_type === 'summative')
         url = `${process.env.REACT_APP_API_S3_UPLOAD}/uploadASSFile?s3path=${process.env.REACT_APP_S3_ASSESSMENT_IMAGES_PATH}`;

      if (props.assessment_type === 'formative')
         url = `${process.env.REACT_APP_API_S3_UPLOAD}/uploadASSFile?s3path=${process.env.REACT_APP_S3_ASSESSMENT_IMAGES_PATH}`;

      axios.post(url, formData).then(response => {
         console.log(response);
         if (response.data) {
            const data = response.data;
            Utils.getBase64(file, imageUrl =>
               setImageURL(imageUrl),
               setLoading(false)
            );

            props.parentCallback("update-image-url", data, props.id);
         }
      }, (error) => {
         console.log(error.message);
      });
   };

   // const handleCallback = (imgb64) => {
   //    props.parentCallback("update-image-url", imgb64, props.id);
   //    setImageURL(imgb64);
   // }

   // const handleUploadImage = file => {
   //    setImageURL('');

   //    const formData = new FormData();
   //    formData.append('file', file);

   //    Utils.getBase64(file, imageb64 =>
   //       handleCallback(imageb64),
   //    );
   // };

   const handleRemoveOption = (idx) => {
      //-- ToDo: Delete uploaded image

      props.parentCallback("remove", "", idx);
   }

   const handleCheckChange = (event) => {
      props.parentCallback("update-check-radio", event.target.checked, props.id);
   }

   // const { loading, imageUrl } = this.state;
   const uploadButton = (
      <div>
         {loading ? <LoadingOutlined /> : <PlusOutlined />}
         <div style={{ marginTop: 8 }}>Upload</div>
      </div>
   );

   return (
      <>
         {/* <Space direction="horizontal" size="small" style={{ display: 'flex' }}> */}
         <div style={{ display: 'flex', alignItems: 'center' }}>
            <Radio
               name={props.cardid}
               checked={props.correct === "1" ? true : false}
               onChange={handleCheckChange}
               disabled={props.mode.includes("assign") || props.mode.includes("view") ? true : false}
            >
               {props.value}
            </Radio>
            <Upload
               id={props.id}
               disabled={props.mode === "answer" || props.mode.includes("assign") || props.mode.includes("view") ? true : false}
               name={props.id}
               listType="picture-card"
               className="avatar-uploader"
               showUploadList={false}
               beforeUpload={beforeUpload}
               accept=".png,.jpg,.gif,.jpeg"
            >
               {imageURL ? <Image height={102.4} src={imageURL} alt={props.id} preview={props.mode === "answer" || props.mode.includes("assign") || props.mode.includes("view") ? true : false} /> : uploadButton}
            </Upload>
            {
               props.mode === "answer" || props.mode.includes("assign") || props.mode.includes("view")
                  ?
                  <></>
                  :
                  <Tooltip placement="right" title='Remove answer key'>
                     <CloseCircleTwoTone twoToneColor="red" style={{ cursor: "pointer", fontSize: "16px" }} onClick={() => handleRemoveOption(props.id)} />
                  </Tooltip>
            }
         </div>
         {/* </Space> */}
      </>
   )
}